<template>
    <v-container>
        <v-stepper
            v-if="!viewReport"
            v-model="e1"
            class="elevation-0"
            style="background: transparent"
        >
            <v-stepper-items>
                <v-stepper-content style="width: 50%; float: right" step="1">
                    <div id="model-select-heading">
                        <h2 class="heading-line-one"> Please select</h2>
                        <h2 class="heading-line-two"> a model</h2>
                        <h2 class="heading-line-three"> to see parts usage.</h2>
                        <div style="margin-top: 50px">
                            <v-btn
                                color="primary"
                                elevation="0"
                                outlined
                                @click="viewReport = true"
                            >
                                <v-progress-circular
                                    slot="loader"
                                    indeterminate
                                    :loading="loading"
                                    color="primary"
                                ></v-progress-circular>
                                <h5 class="heading-line-four"
                                    ><a>View Usage Report</a></h5
                                >
                            </v-btn>
                            <div style="width: 100%; margin-top: 15px">
                                <a
                                    href="https://getac.podbean.com/?utm_source=internalsite&utm_medium=banner"
                                    target="_blank"
                                    ><img
                                        style="width: 90%"
                                        src="../../assets/banners/11-22_728_x_90-ServicePortal.png"
                                /></a>
                            </div>
                        </div>
                    </div>
                    <v-card
                        class="mb-12 elevation-0"
                        style="background-color: transparent"
                    >
                        <v-simple-table id="demo-data-table" dark>
                            <thead>
                                <tr>
                                    <th class="text-center">In Warranty</th>
                                    <th class="text-center">Out of Warranty</th>
                                    <th class="text-center"> Model </th>
                                    <th class="text-center"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="(item, index) in models"
                                    :key="index"
                                >
                                    <td class="text-center">
                                        {{ item.iwQty }}</td
                                    >
                                    <td class="text-center">
                                        {{ item.oowQty }}</td
                                    >
                                    <td class="text-center">{{
                                        item.model
                                    }}</td>
                                    <td class="text-center"
                                        ><v-btn
                                            elevation="0"
                                            color="primary"
                                            text
                                            :disabled="
                                                item.iwQty === 0 && !isTraining
                                            "
                                            @click="
                                                e1 = 2
                                                getSNList(item.model)
                                            "
                                            >Select</v-btn
                                        ></td
                                    >
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </v-card>
                </v-stepper-content>

                <v-stepper-content step="2">
                    <div
                        class="container-bg"
                        style="margin-top: 1%; margin: auto"
                    >
                        <div class="container-fg">
                            <div style="display: flex; flex-direction: row">
                                <img
                                    style="width: 130px; margin-bottom: 1%"
                                    src="../../assets/logos/getac-logo.svg"
                                />
                                <div
                                    class="text-h7 nav-font pl-3"
                                    style="margin-top: 1.3%"
                                    >PARTS USAGE</div
                                >
                            </div>
                            <v-divider
                                style="margin-bottom: 1%; border-color: gray"
                            />
                            <div>
                                <div>
                                    <div
                                        style="
                                            display: flex;
                                            flex-direction: row;
                                        "
                                    >
                                        <v-list-item-avatar
                                            v-if="selectedModel"
                                            tile
                                            size="150px"
                                        >
                                            <v-img
                                                style="
                                                    max-width: 100%;
                                                    height: auto;
                                                "
                                                :src="unitImg"
                                            ></v-img>
                                        </v-list-item-avatar>
                                        <div
                                            style="
                                                display: flex;
                                                flex-direction: column;
                                            "
                                        >
                                            <h1
                                                style="
                                                    font-size: 36px;
                                                    letter-spacing: -0.08rem;
                                                "
                                            >
                                                Selected Model:
                                                {{ selectedModel }}
                                            </h1>
                                            <p
                                                >Please select a serial number
                                                then use the button to apply
                                                usage to the selected serial.</p
                                            >
                                            <div
                                                style="
                                                    display: flex;
                                                    flex-direction: row;
                                                "
                                            >
                                                <v-btn
                                                    dark
                                                    outlined
                                                    style="margin-right: 15px"
                                                    @click="
                                                        e1 = 1
                                                        selectedModel = null
                                                        parts = []
                                                    "
                                                >
                                                    Select Another Model
                                                </v-btn>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <v-divider
                                    style="
                                        margin-bottom: 1%;
                                        border-color: gray;
                                    "
                                />
                            </div>
                            <h5 class="white--text mb-2"
                                >Select Serial Number(s)</h5
                            >
                            <v-autocomplete
                                v-model="selectedSerials"
                                :items="serials"
                                item-text="sn"
                                dark
                                clearable
                                dense
                                filled
                            >
                                <template v-slot:item="{ item }">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ item.sn }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            {{ item.assetTag }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </template>
                            </v-autocomplete>

                            <v-data-table
                                id="demo-data-table"
                                dense
                                :headers="headers"
                                :items="parts"
                                :items-per-page="15"
                                item-key="smId"
                                dark
                            >
                                <template v-slot:item.photo="{ item }">
                                    <v-img
                                        max-width="150"
                                        style="display: block; margin: auto"
                                        :src="`https://rma.getac.com/${item.imgUrl}`"
                                    />
                                </template>
                                <template v-slot:item.action="{ item }">
                                    <v-btn
                                        class="elevation-0"
                                        color="primary"
                                        @click="
                                            useParts(
                                                item.smId,
                                                item.partSpec,
                                                item.invQty
                                            )
                                        "
                                        >Use</v-btn
                                    >
                                </template>
                            </v-data-table>
                        </div>
                    </div>
                </v-stepper-content>
                <v-snackbar v-model="snackbar">
                    {{ snackbarMessage }}

                    <template v-slot:action="{ attrs }">
                        <v-btn
                            text
                            v-bind="attrs"
                            color="white"
                            @click="snackbar = false"
                        >
                            Close
                        </v-btn>
                    </template>
                </v-snackbar>
            </v-stepper-items>
        </v-stepper>
        <usage-report
            v-if="viewReport"
            @back="viewReport = false"
        ></usage-report>
    </v-container>
</template>

<script>
import gql from 'graphql-tag'
import UsageReport from './usage_report.vue'

export default {
    name: 'PartsUsageStepper',
    components: { UsageReport },
    props: {
        models: {
            type: Array,
            required: true
        }
    },
    apollo: {
        querySMInv: {
            skip() {
                return (
                    !this.selectedSerials ||
                    this.selectedSerials.length < 1 ||
                    !this.selectedModel
                )
            },
            query: gql`
                query Query {
                    querySMInv {
                        model
                        partSpec
                        category
                        partNumber
                        invQty
                        smId
                        imgUrl
                    }
                }
            `,
            update(data) {
                const inv = data.querySMInv
                const filtered = inv.filter(
                    (e) => e.model === this.selectedModel
                )
                this.parts = [...filtered].sort((current, next) => {
                    return current.partNumber.localeCompare(next.partNumber)
                })
            }
        }
    },
    data() {
        return {
            e1: 1,
            selectedModel: null,
            serials: [],
            stock: [],
            loader: null,
            loading: false,
            selectedSerials: [],
            snackbar: false,
            snackbarMessage: '',
            viewReport: false,
            headers: [
                {
                    text: 'Photo',
                    align: 'center',
                    sortable: false,
                    value: 'photo'
                },
                {
                    text: 'Part Category',
                    value: 'category',
                    sortable: false,
                    align: 'start'
                },
                {
                    text: 'Part Spec',
                    value: 'partSpec',
                    sortable: false,
                    align: 'start'
                },
                {
                    text: 'Stock Balance',
                    value: 'invQty',
                    sortable: false,
                    align: 'center'
                },
                {
                    text: 'Action',
                    value: 'action',
                    sortable: false,
                    align: 'center'
                }
            ],
            parts: [],
            useItems: []
        }
    },
    computed: {
        unitImg() {
            const name = this.selectedModel.split(`-`)
            const fileName = (`` + name[0]).toLowerCase()
            return (
                process.env.VUE_APP_PUBLIC_ASSETS_URL +
                `/images/units/${fileName}.png`
            )
        },
        isTraining() {
            return (
                this.$auth.user &&
                this.$auth.user.email === 'getaccomputing@usgetac.com'
            )
        }
    },
    methods: {
        getSNList(selectedModel) {
            this.partsList = []
            this.$apollo
                .query({
                    query: gql`
                        query QueryRegisteredSNList($model: String!) {
                            queryRegisteredSNList(model: $model) {
                                sn
                                assetTag
                            }
                        }
                    `,
                    variables: {
                        model: selectedModel
                    }
                })
                .then((data) => {
                    this.serials = data.data.queryRegisteredSNList
                    this.modelSerial = data.data.queryRegisteredSNList[0]
                    this.selectedModel = selectedModel
                })
        },
        useParts(smId, partName, quantity) {
            if (this.selectedSerials.length) {
                if (quantity >= 0) {
                    this.snackbar = false
                    this.$apollo
                        .mutate({
                            mutation: gql`
                                mutation UseItem($smId: String!, $sn: String!) {
                                    useItem(smId: $smId, sn: $sn)
                                }
                            `,
                            variables: {
                                smId: smId,
                                sn: this.selectedSerials
                            }
                        })
                        .then((data) => {
                            if (data.data.useItem === 'success') {
                                this.$apollo.queries.querySMInv.fetchMore({})
                                this.snackbar = true
                                this.snackbarMessage =
                                    'Stock balance for ' +
                                    partName +
                                    ' has been updated successfully'
                            } else {
                                this.snackbarMessage = `${data.data.useItem}`
                                this.snackbar = true
                            }
                        })
                        .catch(() => {
                            this.snackbar = true
                            this.snackbarMessage =
                                'Error updating stock balance'
                        })
                } else {
                    this.snackbar = true
                    this.snackbarMessage = 'Stock balance is already 0'
                }
            } else {
                this.snackbar = true
                this.snackbarMessage = 'Please select a serial number'
            }
        }
    }
}
</script>

<style scoped>
#parts-select {
    margin-right: 100px;
}
.product-card {
    margin: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background-image: linear-gradient(
        155deg,
        rgba(90, 90, 90, 0.6),
        rgba(255, 255, 255, 0)
    );
    border-radius: 10px;
    margin-bottom: 10px;
    border: 1px solid rgba(255, 255, 255, 0.3);
}
.product-card-bg {
    background: rgba(0, 0, 0, 0.349);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}
#model-select-heading {
    font-size: 36px;
    line-height: 0.9;
    letter-spacing: -0.1rem;
    margin-left: 10px;
    position: fixed;
    left: 0;
    /* bottom: 0; */
    margin-bottom: 100px;
    color: white;
}
.heading-line-two {
    color: #db5120 !important;
}
</style>
