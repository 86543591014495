<template>
    <v-container>
        <div class="container-bg">
            <div class="container-fg">
                <v-card-title style="padding: 5px 5px">
                    <div style="display: flex; flex-direction: row">
                        <img
                            style="width: 130px; margin-bottom: 1%"
                            src="../../assets/logos/getac-logo.svg"
                        />
                        <div
                            class="text-h7 nav-font pl-3"
                            style="margin-top: 10px; font-weight: 400"
                            >PARTS USAGE</div
                        >
                    </div>
                    <v-spacer></v-spacer>
                    <v-text-field
                        id="data-text-field"
                        v-model="search"
                        style="
                            width: 10px;
                            margin-bottom: 15px;
                            margin-top: 15px;
                        "
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        dense
                        hide-details
                        clearable
                        outlined
                        dark
                    ></v-text-field>
                </v-card-title>
                <v-divider style="margin-bottom: 1%; border-color: gray" />
                <v-expansion-panels
                    v-model="panel"
                    class="expanded-panels"
                    dark
                    mandatory
                    accordion
                >
                    <v-expansion-panel>
                        <v-expansion-panel-header
                            class="expansion-header-first"
                            expand-icon=""
                        >
                            <h1
                                style="
                                    font-size: 36px;
                                    letter-spacing: -0.08rem;
                                "
                            >
                                Viewing Usage Report
                            </h1>
                            <div
                                style="
                                    display: flex;
                                    justify-content: space-between;
                                "
                            >
                                <v-btn
                                    color="primary"
                                    elevation="0"
                                    @click="$emit('back')"
                                    >Back To Model Selection</v-btn
                                >
                                <v-btn
                                    max-width="200px"
                                    color="primary"
                                    elevation="0"
                                    outlined
                                    @click="exportReport"
                                    >Export Report</v-btn
                                >
                            </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content
                            style="padding: 25px; border-radius: 0px"
                            class="container-fg"
                        >
                            <v-data-table
                                id="request-data-table"
                                dark
                                :items-per-page="10"
                                :search="search"
                                :headers="headers"
                                :items="querySMCustomerPartsUsage"
                                :loading="$apollo.loading"
                                no-data-text="No results available"
                                no-results-text="No results available"
                            >
                                <template v-slot:item.photo="{ item }">
                                    <v-img
                                        max-width="100"
                                        style="display: block; margin: auto"
                                        :src="`https://rma.getac.com//MSS/downloadFileAction.do?REFERENCE_ID=SM_${item.pn}`"
                                    />
                                </template>
                                <template
                                    v-slot:item.lastReplenishedDate="{ item }"
                                >
                                    {{
                                        new Date(
                                            item.lastReplenishedDate
                                        ).toLocaleString()
                                    }}
                                </template>
                            </v-data-table>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </div>
        </div>
    </v-container>
</template>

<script>
import gql from 'graphql-tag'
import { downloadJsonAsExcel } from '@/utils/excel'

export default {
    name: 'UsageReport',
    apollo: {
        querySMCustomerPartsUsage: {
            query: gql`
                query QuerySMCustomerPartsUsage($queryAll: Boolean) {
                    querySMCustomerPartsUsage(queryAll: $queryAll) {
                        customer
                        modelFamily
                        pn
                        category
                        partSpec
                        receivedQty
                        usedQty
                        stockQty
                        lastReplenishedDate
                    }
                }
            `,
            variables: {
                queryAll: false
            }
        }
    },
    props: {},
    data() {
        return {
            search: '',
            panel: 0,
            headers: [
                {
                    text: '',
                    align: 'center',
                    sortable: false,
                    value: 'photo'
                },
                {
                    text: 'Model',
                    align: 'start',
                    sortable: false,
                    value: 'modelFamily'
                },
                {
                    text: 'Category',
                    value: 'category',
                    sortable: false,
                    align: 'start'
                },
                {
                    text: 'Part Spec',
                    value: 'partSpec',
                    sortable: false,
                    align: 'start'
                },
                {
                    text: 'Part #',
                    value: 'pn',
                    sortable: false,
                    align: 'start'
                },
                {
                    text: 'Received',
                    value: 'receivedQty',
                    sortable: false,
                    align: 'center'
                },
                {
                    text: 'Stock',
                    value: 'stockQty',
                    sortable: false,
                    align: 'center'
                },
                {
                    text: 'Used',
                    value: 'usedQty',
                    sortable: false,
                    align: 'center'
                },
                {
                    text: 'Last Replenished',
                    value: 'lastReplenishedDate',
                    sortable: false,
                    align: 'center'
                }
            ]
        }
    },
    methods: {
        exportReport() {
            downloadJsonAsExcel(
                `SMCustomerPartsUsage`,
                `SMCustomerPartsUsage`,
                this.querySMCustomerPartsUsage.map((e) => ({
                    Customer: e.customer,
                    ModelFamily: e.modelFamily,
                    PartNumber: e.pn,
                    Category: e.category,
                    PartSpec: e.partSpec,
                    ReceivedQty: e.receivedQty,
                    UsedQty: e.usedQty,
                    StockQty: e.stockQty,
                    LastReplenishedDate: new Date(
                        e.lastReplenishedDate
                    ).toLocaleString()
                }))
            )
        }
    }
}
</script>
